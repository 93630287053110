import { computed } from 'vue'
import { useStore } from 'vuex'

export default (numRandomPatchTemplates) => {

  // composables
  const store = useStore()

  // computed
  const patchTemplatesByCategory = computed(() => {

    const templatesByCategory = {
      nft: [],
      dex: [],
      unknown: [],
      userContract: [],
      balanceChange: [],
    }

    store.state.app.patchTemplates.forEach((patchTemplate) => {

      if (
        patchTemplate.trigger.type === 'nft'
        || (patchTemplate.trigger.type === 'user-contract' && patchTemplate.contract && (patchTemplate.contract.type === 'erc-721' || patchTemplate.contract.type === 'erc-1155'))
      ) {
        templatesByCategory.nft.push(patchTemplate)

      } else if (
        patchTemplate.trigger.type === 'v2-dex'
        || patchTemplate.trigger.type === 'v3-dex'
      ) {
        templatesByCategory.dex.push(patchTemplate)

      } else if (patchTemplate.trigger.type === 'user-contract') {
        templatesByCategory.userContract.push(patchTemplate)

      } else if (patchTemplate.trigger.type === 'balance-change') {
        templatesByCategory.balanceChange.push(patchTemplate)

      } else {
        templatesByCategory.unknown.push(patchTemplate)
      }

    })

    return templatesByCategory

  })

  const randomPatchTemplates = computed(() => {

    const templatesByCategory = patchTemplatesByCategory.value

    const chosenTemplates = []
    const remainingTemplates = []

    Object.keys(templatesByCategory).forEach((category) => {

      if (chosenTemplates.length === numRandomPatchTemplates) return

      const categoryTemplates = templatesByCategory[category]

      // @NOTE: we use splice below to also remove the template from it's
      //  templatesByCategory array so when we pad out the rest below there
      //  won't be duplicates
      if (categoryTemplates.length !== 0) {
        const randomIndex = Math.floor(Math.random() * categoryTemplates.length)
        chosenTemplates.push(categoryTemplates.splice(randomIndex, 1)[0])
      }

      remainingTemplates.push(...categoryTemplates)

    })

    while (chosenTemplates.length < numRandomPatchTemplates && remainingTemplates.length !== 0) {
      const randomIndex = Math.floor(Math.random() * remainingTemplates.length)
      chosenTemplates.push(remainingTemplates.splice(randomIndex, 1)[0])
    }

    // do the Fisher-Yates shuffle! ᕕ( ᐛ )ᕗ
    for (let index = chosenTemplates.length - 1; index > 0; index--) {
      const swapIndex = Math.floor(Math.random() * (index + 1))
      ;[chosenTemplates[index], chosenTemplates[swapIndex]] = [chosenTemplates[swapIndex], chosenTemplates[index]]
    }

    return chosenTemplates

  })

  return {
    randomPatchTemplates,
    patchTemplatesByCategory,
  }

}
