<template>

  <Header />

  <main>
    <div class="container">

      <section class="upper-section">
        <div class="form-container">
          <h2>Create a Patch</h2>
          <h5>Select a Trigger and Action to create an automation.</h5>
          <form class="create-patch-form" @submit="submit">
            <div class="form-fields">
              <FormSelect :formName="formName" fieldName="triggerId" :isSearchable="true" />
              <FormSelect :formName="formName" fieldName="actionId" :isSearchable="true" />
            </div>
            <div class="footnote-and-button">
              <strong>Next: configure, test and activate your automation.</strong>
              <button type="submit" class="small" :disabled="isSubmitDisabled">Complete Patch</button>
            </div>
          </form>
        </div>
        <PlanUsage type="compact" class="desktop-only" />
      </section>

      <section class="patch-templates">
        <h4>Or get started with one of these templates:</h4>
        <div class="card-list">
          <PatchTemplateCard
            :key="patchTemplate.id"
            :patchTemplateSlug="patchTemplate.slug"
            v-for="patchTemplate in randomPatchTemplates"
          />
        </div>
        <div class="footnote">
          <router-link to="/explore">Explore more templates</router-link>
        </div>
        <div class="mobile-footer">
          <PlanUsage />
        </div>
      </section>

    </div>
  </main>

  <Footer />

</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import useForm from '@/composables/useForm'

  import Header from '@/components/page/Header.vue'
  import Footer from '@/components/page/Footer.vue'
  import PlanUsage from '@/components/etc/PlanUsage.vue'
  import FormSelect from '@/components/forms/FormSelect.vue'
  import PatchTemplateCard from '@/components/etc/PatchTemplateCard.vue'

  import useRandomPatchTemplates from '@/composables/useRandomPatchTemplates'

  export default {
    inject: ['$helpscout'],
    components: {
      Header,
      Footer,
      PlanUsage,
      FormSelect,
      PatchTemplateCard,
    },
    setup() {

      // data
      const formName = 'dashboardCreatePatchForm'

      // composables
      const { form } = useForm({ formName })
      const { randomPatchTemplates } = useRandomPatchTemplates(8)

      return {
        form,
        formName,
        randomPatchTemplates,
      }

    },
    computed: {
      ...mapState('user', ['userFlags']),
      ...mapState('app', ['isAPIReadOnly']),
      ...mapGetters('user', ['arePatchesPseudoPaused']),
      isSubmitDisabled() {
        return (
          this.isAPIReadOnly
          || !this.form.fields.triggerId.value
          || !this.form.fields.actionId.value
        )
      },
    },
    created() {
      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: this.formName, fieldName: 'actionId', newValue: this.isAPIReadOnly })
      this.$store.commit('forms/SET_FIELD_DISABLED', { formName: this.formName, fieldName: 'triggerId', newValue: this.isAPIReadOnly })
    },
    mounted() {
      if (this.arePatchesPseudoPaused && !this.userFlags.hasDismissedPatchesPausedModal) {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'PatchesPseudoPausedModal',
          onClose: () => {
            this.$store.dispatch('user/UPDATE_USER_FLAGS', { hasDismissedPatchesPausedModal: true })
          },
        })
      } else if (!this.userFlags.hasDismissedWelcomeModal) {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'WelcomeModal',
          onClose: () => {
            this.$store.dispatch('user/UPDATE_USER_FLAGS', { hasDismissedWelcomeModal: true, hasDismissedSmartContractAnnouncement: true })
            this.$helpscout.onReady((helpscout) => {
              // @NOTE: the { force: true } here isn't super necessary, since a
              //  new user will definitely not have seen the helpscout message
              //  yet, but it helps during testing when our accounts have
              //  already seen it but we're manually resetting the
              //  hasDismissedWelcomeModal flag in the database
              helpscout('show-message', process.env.VUE_APP_HELPSCOUT_ANNOUNCEMENT_MESSAGE_ID, { force: true })
            })
          },
        })
      } else if (!this.userFlags.hasDismissedSmartContractAnnouncement) {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'SmartContractAnnouncement',
          onClose: () => {
            this.$store.dispatch('user/UPDATE_USER_FLAGS', { hasDismissedSmartContractAnnouncement: true })
          },
        })
      }
    },
    methods: {
      submit($event) {
        if ($event) $event.preventDefault()
        this.$store.commit('forms/SET_FORM_TEMPLATE', {
          formName: 'createPatchForm',
          newTemplate: {
            actionId: this.form.fields.actionId.value,
            triggerId: this.form.fields.triggerId.value,
          },
        })
        this.$router.push({
          name: 'PatchCreate',
          params: {
            referrerLink: 'dashboard-create-patch-form',
          },
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/card-list.styl'

  main
    @apply bg-white

    min-height: calc(100% - 128px)

  .container
    @apply p-4
    @apply pb-16
    @apply mx-auto

  h5
    @apply font-normal
    @apply text-gray-600

  .upper-section
    @apply mt-6

    @apply flex
    @apply gap-8
    @apply items-start

    +breakpoint(sm)
      @apply mt-12

  .form-container
    @apply w-full

  .create-patch-form
    @apply flex
    @apply flex-col
    @apply items-end

    .form-fields
      @apply w-full

      @apply flex
      @apply flex-col

      +breakpoint(lg)
        @apply gap-8
        @apply flex-row

    .footnote-and-button
      @apply flex
      @apply gap-2
      @apply flex-col
      @apply items-end

      +breakpoint(sm)
        @apply gap-4
        @apply flex-row
        @apply items-center

  .desktop-only
    @apply hidden

    +breakpoint(sm)
      @apply block

  .patch-templates
    @apply mt-16

    h4
      @apply mb-6

    .footnote
      @apply my-6
      @apply text-center

    // hide the 4th and 8th cards when the grid has 3 columns
    .card:nth-child(4)
    .card:nth-child(8)
      +breakpoint-between(md, xl)
        @apply hidden

  .mobile-footer
    @apply flex
    @apply gap-8
    @apply flex-col
    @apply items-center

    +breakpoint(sm)
      @apply hidden

</style>
