import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import Mixpanel from '@/plugins/mixpanel'

export default (props) => {

  // composables
  const store = useStore()
  const router = useRouter()

  // methods
  const activatePatchTemplate = (patchTemplateSlug) => {

    // when smart contract function monitoring was introduced in October 2023,
    //  we renamed the "smart-contract-events-emitted" trigger slug to
    //  "smart-contract-activity"
    //
    // likewise, all the patch templates using that trigger id were changed to
    //  replace "events" with "activity", but only AFTER links to those patch
    //  templates on the Explore page were sent out in emails... so this logic
    //  is here to support the old patch template slugs so old links don't break
    const renamedPatchTemplateSlug = patchTemplateSlug.replace('smart-contract-events', 'smart-contract-activity')

    const patchTemplate = store.state.app.patchTemplatesSlugMap[patchTemplateSlug] || store.state.app.patchTemplatesSlugMap[renamedPatchTemplateSlug]

    if (!patchTemplate) return

    const newTemplate = {
      actionId: patchTemplate.action.id,
      triggerId: patchTemplate.trigger.id,
    }

    const mixpanelData = {
      patchTemplate: {
        id: patchTemplate.id,
        name: patchTemplate.name,
        description: patchTemplate.description,
      },
      actionName: patchTemplate.action.name,
      actionSlug: patchTemplate.action.slug,
      triggerName: patchTemplate.trigger.name,
      triggerSlug: patchTemplate.trigger.slug,
    }

    if (patchTemplate.network) {
      newTemplate.networkId = patchTemplate.network.id
      mixpanelData.networkName = patchTemplate.network.name
      mixpanelData.networkSlug = patchTemplate.network.slug
      mixpanelData.patchTemplate.networkName = patchTemplate.network.name
    }

    if (patchTemplate.contract) {

      if (patchTemplate.trigger.type !== 'user-contract') {
        newTemplate.contractId = patchTemplate.contract.id
      } else {
        newTemplate.userContractId = patchTemplate.contract.id
      }

      mixpanelData.contractType = patchTemplate.contract.type
      mixpanelData.contractName = patchTemplate.contract.name
      mixpanelData.contractOwner = patchTemplate.contract.owner
      mixpanelData.contractSymbol = patchTemplate.contract.symbol
      mixpanelData.contractAddress = patchTemplate.contract.address
      mixpanelData.contractImplementationAddress = patchTemplate.contract.implementationAddress

      mixpanelData.patchTemplate.contractType = patchTemplate.contract.type
      mixpanelData.patchTemplate.contractName = patchTemplate.contract.name
      mixpanelData.patchTemplate.contractOwner = patchTemplate.contract.owner
      mixpanelData.patchTemplate.contractSymbol = patchTemplate.contract.symbol
      mixpanelData.patchTemplate.contractAddress = patchTemplate.contract.address
      mixpanelData.patchTemplate.contractImplementationAddress = patchTemplate.contract.implementationAddress

    }

    Mixpanel.onReady((mixpanel) => {
      mixpanel.track('Patch Template Card Clicked', mixpanelData)
    })

    store.commit('forms/SET_FORM_TEMPLATE', {
      formName: 'createPatchForm',
      newTemplate,
    })

    router.push({
      name: 'PatchCreate',
      params: {
        referrerLink: 'patch-template-card',
      },
    })

  }

  return {
    activatePatchTemplate,
  }

}
