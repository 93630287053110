<template>
  <button :disabled="isAPIReadOnly" class="card" :class="{ 'is-loading': !patchTemplate }" @click="activatePatchTemplate(patchTemplate.slug)">
    <LoadingIcon class="self-center" size="medium" v-if="!patchTemplate" />
    <template v-else>
      <div class="icons">
        <img :src="patchTemplate.contract ? patchTemplate.contract.iconUrl : patchTemplate.trigger.iconUrl" />
        <ArrowIcon />
        <img :src="patchTemplate.action.iconUrl" />
      </div>
      <h5 class="name">
        {{ patchTemplate.name }}
      </h5>
      <p class="description">
        {{ patchTemplate.description }}
      </p>
    </template>
  </button>
</template>

<script>

  import { mapState } from 'vuex'

  import useActivatePatchTemplate from '@/composables/useActivatePatchTemplate'

  import ArrowIcon from '@/assets/icons/arrow.svg'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      ArrowIcon,
      LoadingIcon,
    },
    props: {
      patchTemplateSlug: {
        type: String,
        required: true,
      },
    },
    setup(props) {

      // composables
      const { activatePatchTemplate } = useActivatePatchTemplate()

      return {
        activatePatchTemplate,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
      patchTemplate() {
        return this.$store.state.app.patchTemplatesSlugMap[this.patchTemplateSlug]
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .card
    @apply p-8
    @apply w-full
    @apply shadow-md
    @apply text-left
    @apply font-normal
    @apply bg-purple-100
    @apply text-gray-800

    @apply flex-col
    @apply items-start
    @apply justify-start

    @apply mx-auto

    max-width: 16rem
    min-height: 16rem

    +breakpoint(sm)
      max-width: unset

    &.is-loading
      @apply justify-center

    &:hover:not([disabled])
      @apply shadow-lg
      @apply bg-purple-100
      @apply border-purple-100

    .icons
      @apply flex
      @apply space-x-4
      @apply text-gray-700

      svg
      img
        @apply w-8
        @apply h-8

    h5
      @apply mt-4
      @apply mb-2

    .description
      @apply text-base

</style>
